import styles from './header.module.scss';
import {Phone, Burger} from "./SvgIcons";
import MainLogo from "../mainLogo/MainLogo";
import Navigation from "../navigation/Navigation";
import React, {useState} from "react";
import Button from "../button/Button";
import ModalPhones from "../modalPhones/ModalPhones";
import ModalBurger from "../modalBurger/ModalBurger";
import clsx from "clsx";
import ModalForm from "../modalForm/ModalForm";

const Header = () => {
    const [modalPhones, setModalPhones] = useState(false);
    const [burgerMenu, setBurgerMenu] = useState(false);
    const [modalForm, setModalForm] = useState(false);

    const showForm = () => {
        document.body.style.overflow = "hidden";
        setModalForm(true)
    }

    const showBurger = () => {
        document.body.style.overflow = "hidden";
        setBurgerMenu(true)
    }

    const showPhones = () => {
        document.body.style.overflow = "hidden";
        setModalPhones(true)
    }

    return (
        <header>
            <div className={clsx(styles.container, styles.headerContainer)}>
                <MainLogo />
                <Navigation classname={styles.navigation}/>
                <div className={styles.headerContacts}>
                    <div className={styles.contactsWrapper}>
                        <div className={styles.phoneWrapper}>
                            <a href="tel:+375 29 509 05 08">+375 29 509 0 508</a><span> МТС</span>
                        </div>
                        <div className={styles.phoneWrapper}>
                            <a href="tel:+375 44 507 05 09">+375 44 507 0 509</a><span> А1</span>
                        </div>
                    </div>
                    <Button action={showForm} buttonType="buttonWhite">Заказать звонок</Button>
                </div>
                <div className={styles.responsiveNavigation}>
                    <div onClick={showPhones}>{Phone}</div>
                    <div className={styles.burgerIcon} onClick={showBurger}>{Burger}</div>
                </div>
            </div>
            <ModalBurger active={burgerMenu} setActive={setBurgerMenu} />
            <ModalPhones active={modalPhones} setActive={setModalPhones} />
            <ModalForm active={modalForm} setActive={setModalForm} />
        </header>
    );
};

export default Header;
import "@fontsource/montserrat";
import './App.module.scss';
import {Outlet, ScrollRestoration} from "react-router-dom";
import Header from "./components/header/Header";
import React from "react";
import Footer from "./components/footer/Footer";

function App() {

    return (
        <div className="App">

            <Header />

            <Outlet />

            <Footer />

            <ScrollRestoration />

        </div>
    );
}

export default App;

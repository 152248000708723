import {GALLERY_SETTINGS} from "./Settings";
import Slider from "react-slick";
import React from "react";

import CSlide1 from "./img/categorySlider/Cone.jpg";
import CSlide2 from "./img/categorySlider/Ctwo.jpg";
import CSlide3 from "./img/categorySlider/Cthree.jpg";
import CSlide4 from "./img/categorySlider/Cfour.jpg";

import CESlide1 from "./img/categorySlider/CEone.jpg";
import CESlide2 from "./img/categorySlider/CEtwo.jpg";
import CESlide3 from "./img/categorySlider/CEthree.jpg";

import DSlide1 from "./img/categorySlider/Done.jpg";
import DSlide2 from "./img/categorySlider/Dtwo.jpg";
import DSlide3 from "./img/categorySlider/Dthree.jpg";
import DSlide4 from "./img/categorySlider/Dfour.jpg";

export const CategoryC = (
    <Slider {...GALLERY_SETTINGS}>
        <img src={CSlide1} alt="Слайд 1"/>
        <img src={CSlide4} alt="Слайд 4"/>
        <img src={CSlide3} alt="Слайд 3"/>
        <img src={CSlide2} alt="Слайд 2"/>
    </Slider>
)

export const CategoryCE = (
    <Slider {...GALLERY_SETTINGS}>
        <img src={CESlide1} alt="Слайд 1"/>
        <img src={CESlide2} alt="Слайд 2"/>
        <img src={CESlide3} alt="Слайд 3"/>
    </Slider>
)

export const CategoryD = (
    <Slider {...GALLERY_SETTINGS}>
        <img src={DSlide1} alt="Слайд 1"/>
        <img src={DSlide2} alt="Слайд 2"/>
        <img src={DSlide3} alt="Слайд 3"/>
        <img src={DSlide4} alt="Слайд 4"/>
    </Slider>
)
import React from 'react';
import Banner from "../banner/Banner";
import CategoryInfo from "../categoryInfo/CategoryInfo";
import Services from "../services/Services";
import Questions from "../questions/Questions";
import ContactPhones from "../contactPhones/ContactPhones";
import CategorySlider from "../sliders/CategorySlider";
import {useParams} from "react-router-dom";
import {CategoryC, CategoryCE, CategoryD} from "../sliders/СategorySliderImages";


const Categories = () => {
    const { categoryName } = useParams();
    let sliderType;

    switch (categoryName) {
        case 'category_c':
            sliderType = CategoryC;
            break;
        case 'category_ce':
            sliderType = CategoryCE;
            break;
        case 'category_d_after_b':
        case 'category_d_after_c':
            sliderType = CategoryD;
            break;
        default:
            sliderType = false;
            break;
    }

    return (
        <>
            <Banner type="categoryBanner"/>

            <CategoryInfo />

            {sliderType &&
                <CategorySlider slider={sliderType}/>
            }

            <ContactPhones />

            <Services>Другие категории</Services>

            <Questions />

        </>
    );
};

export default Categories;
import React from 'react';
import clsx from "clsx";
import styles from './errorPage.module.scss';
import Button from "../button/Button";
import {Link} from "react-router-dom";

const ErrorPage = () => {
    return (
        <section>
            <div className={clsx(styles.container, styles.errorPage)}>
                <h2 className={styles.title}>404</h2>
                <h2 className={styles.title}>Кажется, Вы попали на несуществующую страницу :(</h2>
                <Link className={styles.homeLink} to={{pathname: '/'}}>
                    <Button buttonType="buttonGold">Перейти на главную</Button>
                </Link>
            </div>
        </section>
    );
};

export default ErrorPage;
import './button.module.scss';
import styles from './button.module.scss'
import clsx from "clsx";

const Button = ({children, buttonType, className, action, ...props}) => {

    return (
        <button {...props} onClick={action} className={clsx(styles.button, styles[buttonType], className)}>
            {children}
        </button>
    );
};

export default Button;
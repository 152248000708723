import React from 'react';
import clsx from "clsx";
import styles from "./modalForm.module.scss"
import FeedbackForm from "../feedbackForm/FeedbackForm";

const ModalForm = ({active, setActive}) => {

    const closeModal = () => {
        document.body.style.overflow = "";
        setActive(false)
    }

    return (
        <div className={clsx(styles.modalForm, active && styles.active)} onClick={closeModal}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <FeedbackForm isModal={'isModal'}></FeedbackForm>
                <div className={styles.closeModal} onClick={closeModal}>Закрыть</div>
            </div>
        </div>
    );
};

export default ModalForm;
import React from 'react';
import clsx from "clsx";
import styles from "./modalPhones.module.scss"

const ModalPhones = ({active, setActive}) => {

    const closeModal = () => {
        document.body.style.overflow = "";
        setActive(false)
    }

    return (
        <div className={clsx(styles.modalPhones, active && styles.active)} onClick={closeModal}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <div className={styles.phoneWrapper}>
                    <span> А1</span><a href="tel:+375 44 507 05 09">+375 44 507 0 509</a>
                </div>
                <div className={styles.phoneWrapper}>
                    <span> МТС</span><a href="tel:+375 29 509 05 08">+375 29 509 0 508</a>
                </div>
                <div className={styles.closeModal} onClick={closeModal}>Закрыть</div>
            </div>
        </div>
    );
};

export default ModalPhones;
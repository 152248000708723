import React from 'react';
import styles from './popularQuestions.module.scss'
import clsx from "clsx";

const PopularQuestions = () => {
    return (
        <section className={styles.popularQuestions}>
            <h2 className={styles.popularQuestionsTitle}>Часто задаваемые вопросы</h2>
            <div className={styles.firstItem}>
                <div className={clsx(styles.container, styles.popularQuestionsContainer)}>
                    <div className={styles.firstQuestionsWrapper}>
                        <h2 className={styles.questionsTitle}>Какие документы необходимы для обучения?</h2>
                        <h3 className={styles.questionsText}>Паспорт, водительское удостоверение,
                            медицинская справка на соответствующую категорию и фото 3х4 см – 2 шт.
                        </h3>
                        <h2 className={styles.questionsTitle}>Предусмотрены ли у Вас скидки на обучение?</h2>
                        <h3 className={styles.questionsText}>Мы предоставляем скидки для лиц, проходящих у нас
                            обучение по нескольким категориям - 10 %, многодетных - 7 %, для студентов - 10 %, для
                            подписчиков нашего <a className={styles.instagramLink} href="https://www.instagram.com/vipvozhdenie/">
                                Instagram (@vipvozhdenie)</a> - 20 BYN. А также у нас длинная рассрочка без
                            банков и процентов, бесплатная подача автомобиля для сдачи экзамена в ГАИ (в составе своей
                            учебной группы).</h3>
                        <h2 className={styles.questionsTitle}>Как вы помогаете с трудоустройством?</h2>
                        <h3 className={styles.questionsText}>Проведем практическую стажировку на настоящей фуре и
                            снимем видео тест-драйва. Дадим рекомендательное письмо, порекомендуем Вас
                            нашим компаниям-партнерам.
                        </h3>
                        <h2 className={styles.questionsTitle}>Как долго длится обучение?</h2>
                        <h3 className={styles.questionsText}>По категориям "A", "C", "CE" - в пределах полутора месяцев, по категории "D" - в пределах 2.5 месяцев.</h3>
                    </div>
                </div>
            </div>
            <div className={styles.lastItem}>
                <div className={clsx(styles.container, styles.popularQuestionsContainer)}>
                    <div className={styles.lastQuestionsWrapper}>
                        <h2 className={styles.questionsTitle}>Кто преподает у Вас?</h2>
                        <h3 className={styles.questionsText}>Все наши преподователи имеют большой стаж работы в сфере
                            подготовки водителей. Они с радостью поделятся с Вами накопленным опытом и помогут уверенно
                            себя чувствовать на дороге.
                        </h3>
                        <h2 className={styles.questionsTitle}>Где проходит квалификационный экзамен в ГАИ?</h2>
                        <h3 className={styles.questionsText}>Сдача итогового экзамена осуществляется в МЭО ГАИ города Минска (ул. Семашко, 17).
                            Иногородние учащиеся имеют право также сдавать экзамен по месту регистрации в других территориальных подразделениях ГАИ.
                        </h3>
                        <h2 className={styles.questionsTitle}>Какое время проходят теоретические занятия?</h2>
                        <h3 className={styles.questionsText}>Занятия по теории проводятся по средам и пятницам в 10.00 и 18.00 (время начала по Вашему выбору).
                            Графики проведения данных занятий мы сообщаем нашим учащимся при помощи SMS-рассылки.
                        </h3>
                        <h2 className={styles.questionsTitle}>У меня нет опыта вождения крупного авто. Я справлюсь?</h2>
                        <h3 className={styles.questionsText}>Да, курс подходит абсолютным новичкам без опыта
                            вождения автомобиля с большими габаритами. Главное - уверенность в себе, а в остальном
                            помогут наши практикующие инструкторы, которые будут сопровождать Вас на протяжении всего курса.
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PopularQuestions;
import React from 'react';
import styles from "./contacts.module.scss";
import clsx from "clsx";
import YandexMap from "../yandexMap/YandexMap";
import FeedbackForm from "../feedbackForm/FeedbackForm";

const Contacts = () => {
    return (
        <section id="contactUs" className={styles.contacts}>
            <h1 className={styles.contactsTitle}>Контакты</h1>
            <div className={clsx(styles.container, styles.contactsContainer)}>
                <div className={styles.infoContainer}>
                    <div className={styles.infoItem}>
                        <div><div className={styles.itemTitle}>Адрес офиса</div>
                            <div className={styles.itemText}>Пр-кт имени газеты "Звязда", д. 61, каб. 34</div>
                            <div className={styles.itemTitle}>Адрес автодрома</div>
                            <div className={styles.itemText}>Авторынок "Малиновка"</div>
                        </div>
                        <div>
                            <div className={styles.itemTitle}>Банковские реквизиты</div>
                            <div className={styles.itemText}>Рассчетный счет BY82ALFA30122A60010010270000 в ЗАО "Альфа-Банк",
                                БИК ALFABY2X
                            </div>
                            <div className={styles.itemTitle}>ООО «Империя Вождения»</div>
                            <div className={styles.itemText}>УНП 193291730</div>
                        </div>
                    </div>
                </div>
                <YandexMap />
                <FeedbackForm />
            </div>
        </section>
    );
};

export default Contacts;
import React from 'react';
import './sliderStyles.scss';
import styles from './sliders.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx";

const CategorySlider = ({slider}) => {
    return (
        <section className={clsx(styles.slider, styles.categoryGallerySlider)}>
            <h2 className={styles.sliderTitle}>Обновленный автопарк</h2>
            <div className={clsx(styles.container, styles.sliderContainer)}>
                {slider}
            </div>
        </section>
    );
};

export default CategorySlider;
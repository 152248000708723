import Licence from './img/licence.jpg';
import Category from './img/category.jpeg';
import Gai from './img/gai.jpg';
import Autopark from './img/autopark.jpg';
import Wildberries from './img/wildberries.jfif';
import NewRules from './img/newrules.jpg';
import Wheel from './img/wheel.jpg';


export const NewsList = [
    {
        id: 1,
        title: 'С 23 марта 2024 года водительские удостоверения в Республике Беларусь будут выдаваться на 20 лет',
        text: 'Хорошая новость для выпускников автошколы «Империя Вождения». С 23 марта текущего года учащиеся, ' +
            'успешно сдавшие квалификационный экзамен в ГАИ, получат на руки водительские удостоверения, срок ' +
            'действия которых составит 20 лет. Данная норма предусмотрена Указом Президента Республики Беларусь от ' +
            '16.02.2024 г. № 55. При этом максимальный срок изготовления нового удостоверения составит не более трех рабочих дней.' +
            '<br/><br/>' +
            'Законодательство также не запрещает обменять на новое ещё действующее водительское удостоверение.'
        ,
        image: Licence,
        imageAlt: 'Водительское удостоверение',
        date: '22.03.2024'
    },
    {
        id: 2,
        title: 'Открыт новый набор в учебные группы',
        text: 'Автошкола «Империя Вождения» сообщает об открытии набора в учебные группы по переподготовке водителей всех категорий:' +
            '<br/><br/>' +
            '- с 25 марта 2024 г. старт обучения в группах по категориям «А», «С», «D»;' +
            '<br/><br/>' +
            '- с 5 апреля 2024 г. старт обучения в группах по категориям «А», «С», «СЕ», «D».' +
            '<br/><br/>' +
            'Приглашаем Вас!'
        ,
        image: Category,
        imageAlt: 'Набор в учебные группы',
        date: '23.03.2024'
    },
    {
        id: 3,
        title: 'Здание МЭО ГАИ на ул. Семашко, 17 закрыто на ремонт. Изменяется порядок прибытия выпускников автошколы на экзамен в ГАИ',
        text: 'С 16 апреля 2024 г. здание МЭО ГАИ (ул. Семашко, 17) закрыто на реконструкцию. Теперь оформление ' +
            'заявления на выдачу водительского удостоверения, фото на удостоверение будут осуществляться в здании ГАИ ' +
            'ГУВД Мингорисполкома (проспект Дзержинского, 3-211).' +
            '<br/><br/>' +
            'Запись на практический экзамен, выдача водительских удостоверений после сдачи экзаменов остаются на ул. Семашко, 17.' +
            '<br/><br/>' +
            'Место сдачи практического экзамена не изменяется. ' +
            '<br/><br/>' +
            'Такими образом, после оформления всех необходимых документов и фотографирования на водительские ' +
            'удостоверения выпускники автошколы «Империя Вождения» с проспекта Дзержинского, 3 приезжают на улицу ' +
            'Семашко, 17 для итоговой сдачи практического экзамена по вождению.'
        ,
        image: Gai,
        imageAlt: 'МЭО ГАИ',
        date: '16.04.2024'
    },
    {
        id: 4,
        title: 'Стартовал набор в новые учебные группы',
        text: 'Автошкола «Империя Вождения» сообщает об открытии набора в учебные группы по переподготовке водителей ' +
            'в городе Минске всех категорий:' +
            '<br/><br/>' +
            '- с 14 июня 2024 г. старт обучения в группах по категориям переподготовки «А», «С», «СЕ», «D».' +
            '<br/><br/>' +
            'Приглашаем Вас!'
        ,
        image: Autopark,
        imageAlt: 'Автопарк',
        date: '17.04.2024'
    },
    {
        id: 5,
        title: 'Теперь автомобиль можно купить на Wildberries',
        text: 'А вы знаете, что автомобиль можно купить в интернет-магазине? Да, сейчас такое возможно. На маркетплейсе ' +
            'Wildberries любой желающий может приобрести новое авто следующих марок: CHERY, JAECOO, Omoda, Zeekr, Voyah,' +
            ' Kia, Hyundai и др. А жители одного из российских регионов - Волгоградской области – могут приобрести и ' +
            'автомобили отечественного производства Lada.' +
            '<br/><br/>' +
            'Ну, а получить дополнительные навыки вождения вы всегда можете в лучшей автошколе города Минска «Империя Вождения».'
        ,
        image: Wildberries,
        imageAlt: 'Маркетплейс',
        date: '11.06.2024'
    },
    {
        id: 6,
        title: 'Изменились требования к учебным автомобилям при сдаче экзамена в ГАИ',
        text: 'С 28 июля 2024 года вступили в силу изменения в Инструкцию о порядке приема квалификационных экзаменов ' +
            'на право управления механическим транспортным средством (за исключением колесного трактора). Так, согласно ' +
            'новым требованиям, для проведения практического экзамена могут использоваться: ' +
            '<br/><br/>' +
            '- мотоцикл с рабочим объемом не менее 220 кубических сантиметров;' +
            '<br/>' +
            '- учебный автомобиль категории «С» с технически допустимой общей массой более 8 тонн;' +
            '<br/>' +
            '- учебный автобус не менее чем с 23 местами для сидения пассажиров (категория «D»);' +
            '<br/>' +
            '- состав транспортных средств, в который входит учебный автомобиль категории «С» и прицеп, технически ' +
            'допустимая общая масса которого превышает 2000 килограммов, имеющий не менее двух осей с расстоянием ' +
            'между ними более 2 метров либо длину более 5 метров (категория «CE»).' +
            '<br/><br/>' +
            'Для учащихся автошколы «Империя Вождения» это не станет препятствием для обучения и сдачи экзаменов, ' +
            'так мы подготовились к ним и имеем в своем автопарке учебные транспортные средства, соответствующие ' +
            'всем новым требованиям.' +
            '<br/><br/>' +
            'Ждём Вас на обучение!'
        ,
        image: NewRules,
        imageAlt: 'Новые правила',
        date: '28.07.2024'
    },
    {
        id: 7,
        title: 'Очередной набор в новые учебные группы',
        text: 'Автошкола «Империя Вождения» сообщает об открытии набора в учебные группы по переподготовке водителей ' +
            'в городе Минске:' +
            '<br/><br/>' +
            '- с 7 августа 2024 г. старт обучения в группах по категориям переподготовки «С», «СЕ», «D».' +
            '<br/><br/>' +
            'Сдача экзамена в ГАИ на учебной технике, соответствующей всем новым требованиям.' +
            '<br/><br/>' +
            'Приглашаем Вас!'
        ,
        image: Wheel,
        imageAlt: 'Водитель',
        date: '29.07.2024'
    },
]
import {createBrowserRouter} from "react-router-dom";
import PromotionsPage from "./components/pages/PromotionsPage";
import App from "./App";
import React from "react";
import HomePage from "./components/pages/HomePage";
import Categories from "./components/pages/Categories";
import ContactsPage from "./components/pages/ContactsPage";
import NewsPage from "./components/pages/NewsPage";
import NotFound from "./components/pages/NotFound";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <App />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/promotions",
                element: <PromotionsPage />,
            },
            {
                path: "/contacts",
                element: <ContactsPage />,
            },
            {
                path: "/categories/:categoryName",
                element: <Categories />,
            },
            {
                path: "/news/:newsId",
                element: <NewsPage />,
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
]);
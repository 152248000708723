import React from 'react';
import clsx from "clsx";
import styles from "./contactPhones.module.scss";

const ContactPhones = () => {
    return (
        <section className={styles.contactPhones}>
            <div className={clsx(styles.container, styles.phonesContainer)}>
                <a className={styles.phonesItem} href="tel:+375 29 509 0 508">
                    <div className={styles.itemProvider}>МТС</div>
                    <div>+375 29 509 0 508</div>
                </a>
                <a className={styles.phonesItem} href="tel:+375 44 507 0 509">
                    <div className={styles.itemProvider}>А1</div>
                    <div>+375 44 507 0 509</div>
                </a>
                <a className={styles.phonesItem} href="tel:+375 17 300 7 300">
                    <div className={styles.itemProvider}>Городской</div>
                    <div>+375 17 300 7 300</div>
                </a>
            </div>
        </section>
    );
};

export default ContactPhones;
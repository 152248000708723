import React from "react";

function SampleNextArrow({className, style, onClick, ...props}) {
    return (
        <svg onClick={onClick} style={style} className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 35.1673L28.5416 21.1673C28.7083 21.0006 28.8266 20.8201 28.8966 20.6257C28.9666 20.4312 29.0011 20.2229 29 20.0007C29 19.7784 28.965 19.5701 28.895 19.3757C28.825 19.1812 28.7072 19.0007 28.5416 18.834L14.5 4.79232C14.1111 4.40343 13.625 4.20898 13.0416 4.20898C12.4583 4.20898 11.9583 4.41732 11.5416 4.83398C11.125 5.25065 10.9166 5.73676 10.9166 6.29232C10.9166 6.84787 11.125 7.33398 11.5416 7.75065L23.7916 20.0007L11.5416 32.2507C11.1528 32.6395 10.9583 33.119 10.9583 33.689C10.9583 34.259 11.1666 34.7518 11.5833 35.1673C12 35.584 12.4861 35.7923 13.0416 35.7923C13.5972 35.7923 14.0833 35.584 14.5 35.1673Z" fill="#FBCD20"/>
        </svg>
    );
}

function SamplePrevArrow({className, style, onClick, ...props}) {
    return (
        <svg onClick={onClick} style={style} className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 35.1673L11.4584 21.1673C11.2917 21.0006 11.1734 20.8201 11.1034 20.6257C11.0334 20.4312 10.9989 20.2229 11 20.0007C11 19.7784 11.035 19.5701 11.105 19.3757C11.175 19.1812 11.2928 19.0007 11.4584 18.834L25.5 4.79232C25.8889 4.40343 26.375 4.20898 26.9584 4.20898C27.5417 4.20898 28.0417 4.41732 28.4584 4.83398C28.875 5.25065 29.0834 5.73676 29.0834 6.29232C29.0834 6.84787 28.875 7.33398 28.4584 7.75065L16.2084 20.0007L28.4584 32.2507C28.8472 32.6395 29.0417 33.119 29.0417 33.689C29.0417 34.259 28.8334 34.7518 28.4167 35.1673C28 35.584 27.5139 35.7923 26.9584 35.7923C26.4028 35.7923 25.9167 35.584 25.5 35.1673Z" fill="#FBCD20"/>
        </svg>
    );
}

export const GALLERY_SETTINGS = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                className: "center",
                centerMode: true,
                slidesToShow: 1,
            }
        },
    ]
}

export const REVIEWS_SETTINGS = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
                // className: "center",
                // centerMode: true,
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 575,
            settings: {
                // className: "center",
                // centerMode: true,
                slidesToShow: 1,
            }
        },
    ]
}

export const NEWS_SETTINGS = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}
import React from 'react';
import Questions from "../questions/Questions";
import Promotions from "../promotions/Promotions";
import {Helmet} from "react-helmet";

const PromotionsPage = () => {
    return (
        <>
            <Helmet>
                <title data-react-helmet="true">Акции в автошколе "Империя Вождения" в Минске</title>
                <meta data-react-helmet="true" name="description"
                      content="Акции и Скидки в Автошколе Империя Вождения в Минске: ✅ для студентов, ✅ для
                      выпускников, ✅ для друзей. Более подробная информация по телефонам: ☎️ +375 29 509 0
                      508 +375 44 507 0 509 Звоните!"/>
                <meta data-react-helmet="true" property="og:type" content="website"/>
                <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/promotions/"/>
                <meta data-react-helmet="true" property="og:title" content='Акции в автошколе "Империя Вождения" в Минске'/>
                <meta data-react-helmet="true" property="og:description"
                      content="Акции и Скидки в Автошколе Империя Вождения в Минске: ✅ для студентов, ✅
                      для выпускников, ✅ для друзей. Более подробная информация по телефонам: ☎️ +375 29 509 0 508
                      +375 44 507 0 509 Звоните!"/>
            </Helmet>

            <Promotions/>

            <Questions/>

        </>
    );
};

export default PromotionsPage;
export const Phone = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.2616 23.5106L25.5033 24.2656C25.5033 24.2656 23.6983 26.0589 18.7733 21.1623C13.8483 16.2656 15.6533 14.4723 15.6533 14.4723L16.1299 13.9956C17.3083 12.8256 17.4199 10.9456 16.3916 9.57228L14.2916 6.76728C13.0183 5.06728 10.5599 4.84228 9.10159 6.29228L6.48492 8.89228C5.76326 9.61228 5.27992 10.5423 5.33826 11.5756C5.48826 14.2206 6.68492 19.9089 13.3583 26.5456C20.4366 33.5823 27.0783 33.8623 29.7933 33.6089C30.6533 33.5289 31.3999 33.0923 32.0016 32.4923L34.3683 30.1389C35.9683 28.5506 35.5183 25.8256 33.4716 24.7139L30.2883 22.9823C28.9449 22.2523 27.3116 22.4673 26.2616 23.5106Z" fill="#FBCD20"/>
    </svg>
)

export const Burger = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.875 11.875H33.125M6.875 20H33.125M6.875 28.125H33.125" stroke="#F5F5F5" strokeWidth="3.75" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
)
import React from 'react';
import styles from './services.module.scss'
import clsx from "clsx";
import {Link, useParams} from "react-router-dom";
import {Clock, Wheel, Book} from "./SvgIcons";
import CategoryA from '../../img/bannerAndServices/categoryA.png';
import CategoryC from '../../img/bannerAndServices/categoryC.png';
import CategoryCE from '../../img/bannerAndServices/categoryCE.png';
import CategoryDB from '../../img/bannerAndServices/categoryDB.png';
import CategoryDC from '../../img/bannerAndServices/categoryDC.png';
import internationalDriver from '../../img/bannerAndServices/tir.png';
import additionalLessons from '../../img/bannerAndServices/additionals.png';
import individualLessons from '../../img/bannerAndServices/individual.png';

const INFO = [
    {id: 1, link: 'category_a', title: 'Категория A', text: 'Переподготовка с категории B', time: '1 месяц', practice: '18 часов практики', lecture: '', price: '400 р.', img: CategoryA},
    {id: 2, link: 'category_c', title: 'Категория C', text: 'Переподготовка с категории B', time: '1 месяц', practice: '19 часов практики', lecture: '136 часов лекций', price: '650 р.', img: CategoryC},
    {id: 3, link: 'category_ce', title: 'Категория CE', text: 'Переподготовка с категории C', time: '1 месяц', practice: '25 часов практики', lecture: '64 часа лекций', price: '720   р.', img: CategoryCE},
    {id: 4, link: 'category_d_after_b', title: 'Категория D', text: 'Переподготовка с категории B', time: '2 месяца', practice: '51 час практики', lecture: '177 часов лекций', price: '900 р.', img: CategoryDB},
    {id: 5, link: 'category_d_after_c', title: 'Категория D', text: 'Переподготовка с категории C', time: '1.5 месяца', practice: '28 часов практики', lecture: '141 час лекций', price: '700 р.', img: CategoryDC},
    {id: 6, link: 'international_driver', title: 'Водитель-международник', text: 'Курсы повышения квалификации водителей-международников', time: '4 дня', practice: '', lecture: '36 часов лекций', price: '120 р.', img: internationalDriver},
    {id: 7, link: 'additional_lessons', title: 'Стажировка на фуре и тест-драйв', text: 'Практикуйтесь в вождении для будущего трудоустройства', time: 'от 1 часа', practice: '', lecture: '', price: 'от 100 р.', img: additionalLessons},
    {id: 8, link: 'individual_lessons', title: 'Индивидуальные занятия', text: 'Практикуйтесь в вождении', time: 'от 1 часа', practice: '', lecture: '', price: 'от 40 р.', img: individualLessons},
]

const Services = ({children}) => {
    const { categoryName } = useParams();

    return (
        <section id="services" className={styles.services}>
            <h2 className={styles.servicesTitle}>{children}</h2>
            <div className={clsx(styles.container, styles.servicesContainer)}>
                {INFO.map(item =>
                    <Link className={clsx(styles.servicesItem, categoryName === item.link && styles.active) } to={'/categories/' + item.link.toLowerCase()} key={item.id}>
                        <div className={styles.itemTitle}>{item.title}</div>
                        <div className={styles.itemText}>{item.text}</div>
                        <div className={styles.additionalInformation}>{Clock}{item.time}</div>
                        {item.practice &&
                        <div className={styles.additionalInformation}>{Wheel}{item.practice}</div>}
                        {item.lecture &&
                        <div className={styles.additionalInformation}>{Book}{item.lecture}</div>}
                        <div className={styles.price}>{item.price}</div>
                        <img className={clsx(styles.image, item.link === 'international_Driver' && styles.internationalDriver, item.link === 'additional_Lessons' && styles.additionalLessons)} src={item.img} alt=""/>
                    </Link>
                )}
            </div>
        </section>
    );
};

export default Services;
import React from 'react';
import styles from "./mainLogo.module.scss";
import logo from "./logo.png";
import {Link} from "react-router-dom";
import clsx from "clsx";

const MainLogo = (props) => {
    return (
        <Link aria-label="Логотип" className={clsx(styles.logoLink, props.classname)} to={'/'}>
            <img className={styles.mainLogo} src={logo} alt="Автошкола &quot;Империя Вождения&quot;"/>
        </Link>
    );
};

export default MainLogo;
import {Helmet} from "react-helmet";
import React from "react";

export const CategoryA = (
    <Helmet>
        <link rel="canonical" href="https://vipvozhdenie.by/categories/category_a/"/>
        <title data-react-helmet="true">Переподготовка на категорию A с B в Минске - Мотошкола в Минске</title>
        <meta data-react-helmet="true" name="description"
              content="Получи категорию A всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории B на А в Минске. Обучение вождению на категорию A. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения B и A в автошколе Империя Вождения ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
        <meta data-react-helmet="true" name="keywords"
              content="категория a, категория а, категория мотоцикл, минск, права, обучение, переобучение, сдать, получить, автошкола, мотошкола, переподготовка, стоимость, перевести, отучиться, цена"/>
        <meta data-react-helmet="true" property="og:type" content="website"/>
        <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/categories/category_a/"/>
        <meta data-react-helmet="true" property="og:title"
              content="Переподготовка на категорию A с B в Минске - Мотошкола в Минске"/>
        <meta data-react-helmet="true" property="og:description"
              content="Получи категорию A всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории B на А в Минске. Обучение вождению на категорию A. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения B и A в автошколе Империя Вождения ☎️ +375(29)509-0-508 +375(44)507-0-509"/>
    </Helmet>
)

export const CategoryC = (
    <Helmet>
        <link rel="canonical" href="https://vipvozhdenie.by/categories/category_c/"/>
        <title data-react-helmet="true">Переподготовка с категории B на C в Минске - Автошкола в Минске</title>
        <meta data-react-helmet="true" name="description"
              content="Получи категорию C всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории B на C в Минске. Обучение вождению на категорию С. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения B и C в автошколе Империя Вождения ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
        <meta data-react-helmet="true" name="keywords"
              content="категория c, категория с, категория ц, минск, права, обучение, переобучение, сдать, получить, автошкола, переподготовка, стоимость"/>
        <meta data-react-helmet="true" property="og:type" content="website"/>
        <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/categories/category_c/"/>
        <meta data-react-helmet="true" property="og:title"
              content="Переподготовка с категории B на C в Минске - Автошкола в Минске"/>
        <meta data-react-helmet="true" property="og:description"
              content="Получи категорию C всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории B на C в Минске. Обучение вождению на категорию С. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения B и C в автошколе Империя Вождения ☎️ +375(29)509-0-508 +375(44)507-0-509"/>
    </Helmet>
)

export const CategoryCE = (
    <Helmet>
        <link rel="canonical" href="https://vipvozhdenie.by/categories/category_ce/"/>
        <title data-react-helmet="true">Переподготовка с категории C на E в Минске - Автошкола в Минске</title>
        <meta data-react-helmet="true" name="description"
              content="Получи категорию СЕ всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории C на CE в Минске. Обучение вождению на категорию E. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения Е и C в автошколе Империя Вождения ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
        <meta data-react-helmet="true" name="keywords"
              content="категория ce, категория се, категория е, минск, права, обучение, переобучение, сдать, получить, автошкола, переподготовка, стоимость"/>

        <meta data-react-helmet="true" property="og:type" content="website"/>
        <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/categories/category_ce/"/>
        <meta data-react-helmet="true" property="og:title"
              content="Переподготовка с категории C на E в Минске - Автошкола в Минске"/>
        <meta data-react-helmet="true" property="og:description"
              content="Получи категорию СЕ всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории C на CE в Минске. Обучение вождению на категорию E. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения Е и C в автошколе Империя Вождения ☎️ +375(29)509-0-508 +375(44)507-0-509"/>
    </Helmet>
)

export const CategoryDB = (
    <Helmet>
        <link rel="canonical" href="https://vipvozhdenie.by/categories/category_d_after_b/"/>
        <title data-react-helmet="true">Переподготовка с категории B на D в Минске - Автошкола в Минске</title>
        <meta data-react-helmet="true" name="description"
              content="Получи категорию D всего за 2 месяца🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории B на D в Минске. Обучение вождению на категорию Д. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения D и B в автошколе Империя Вождения ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
        <meta data-react-helmet="true" name="keywords"
              content="категория d, категория д, категория с д, минск, права, обучение, переобучение, сдать, получить, автошкола, переподготовка, стоимость, перевести, отучиться, цена, категория д е"/>
        <meta data-react-helmet="true" property="og:type" content="website"/>
        <meta data-react-helmet="true" property="og:url"
              content="https://vipvozhdenie.by/categories/category_d_after_b/"/>
        <meta data-react-helmet="true" property="og:title"
              content="Переподготовка с категории B на D в Минске - Автошкола в Минске"/>
        <meta data-react-helmet="true" property="og:description"
              content="Получи категорию D всего за 2 месяца🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории B на D в Минске. Обучение вождению на категорию Д. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения D и B в автошколе Империя Вождения ☎️ +375(29)509-0-508 +375(44)507-0-509"/>
    </Helmet>
)

export const CategoryDC = (
    <Helmet>
        <link rel="canonical" href="https://vipvozhdenie.by/categories/category_d_after_c/"/>
        <title data-react-helmet="true">Переподготовка с категории C на D в Минске - Автошкола в Минске</title>
        <meta data-react-helmet="true" name="description"
              content="Получи категорию D всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории C на D в Минске. Обучение вождению на категорию Д. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения D и С в автошколе Империя Вождения ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
        <meta data-react-helmet="true" name="keywords"
              content="категория d, категория д, категория с д, минск, права, обучение, переобучение, сдать, получить, автошкола, переподготовка, стоимость, перевести, отучиться, цена, категория д е"/>
        <meta data-react-helmet="true" property="og:type" content="website"/>
        <meta data-react-helmet="true" property="og:url"
              content="https://vipvozhdenie.by/categories/category_d_after_c/"/>
        <meta data-react-helmet="true" property="og:title"
              content="Переподготовка с категории C на D в Минске - Автошкола в Минске"/>
        <meta data-react-helmet="true" property="og:description"
              content="Получи категорию D всего за 1 месяц🚗 Рассрочка✅ Большой Процент Сдачи✅ Переподготовка с категории C на D в Минске. Обучение вождению на категорию Д. Курсы вождения грузовых и легковых автомобилей. Лучшая цена! Получить права вождения D и С в автошколе Империя Вождения ☎️ +375(29)509-0-508 +375(44)507-0-509"/>
    </Helmet>
)

export const internationalDriver = (
    <Helmet>
        <link data-react-helmet="true" rel="canonical"
                  href="https://vipvozhdenie.by/categories/international_driver/"/>
            <meta data-react-helmet="true" property="og:url"
                  content="https://vipvozhdenie.by/categories/international_driver/"/>
            <title data-react-helmet="true">Курсы водителя-международника в Минске. Переподготовка и обучение</title>
            <meta data-react-helmet="true" name="description"
                  content="Курсы водителя-международника TIR✅ (грузы и пассажиры), выполняющих международные перевозки грузов и пассажиров — Автошкола Империя Вождения в Минске обучает по доступным ценам – переподготовка водителей ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
    </Helmet>
)

export const additionalLessons = (
    <Helmet>
            <link data-react-helmet="true" rel="canonical"
                  href="https://vipvozhdenie.by/categories/additional_lessons/"/>
            <meta data-react-helmet="true" property="og:url"
                  content="https://vipvozhdenie.by/categories/additional_lessons/"/>
            <title data-react-helmet="true">Стажировка водителей-международников в Минске. Переподготовка и
                    обучение</title>
            <meta data-react-helmet="true" name="description"
                  content="Стажировка водителей-международников ✅ без опыта работы ✅ | Работа для водителей-международников категории СЕ в Польше, Литве и России — Автошкола Империя Вождения в Минске обучает по доступным ценам – переподготовка водителей ☎️ +375 29 509 0 508 +375 44 507 0 509" />
    </Helmet>
)

export const individualLessons = (
    <Helmet>
        <link data-react-helmet="true" rel="canonical" href="https://vipvozhdenie.by/categories/individual_lessons/" />
        <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/categories/individual_lessons/" />
        <title data-react-helmet="true">Индивидуальные уроки вождения в Минске. Практика вождения и обучение</title>
        <meta data-react-helmet="true" name="description" content="Индивидуальные уроки вождения в Минске.  Лучшие автоинструкторы по низким ценам, рейтингам и реальные отзывы✅ Индивидуальный подход, бесплатный подбор, удобный график. ✅ На рынке с 2014 года. Автошкола Империя Вождения в Минске ☎️ +375 29 509 0 508 +375 44 507 0 509" />
    </Helmet>
)

export const kursy_voditeley_taksi = (
    <Helmet>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://vipvozhdenie.by/kursy_voditeley_taksi/"/>
        <meta property="og:title"
              content="Курсы повышения квалификации водителей такси в Минске | Автошкола Империя Вождения"/>
        <meta property="og:description"
              content="⚡ Курсы повышения квалификации для таксистов в Минске - автошкола Империя вождения. ✅ Стоимость прохождения курса таксиста категории B 🚖 на сайте или по телефону +375 29 509 0 508"/>
    </Helmet>
)


import React from 'react';
import Banner from "../banner/Banner";
import Questions from "../questions/Questions";
import Advantages from "../advantages/Advantages";
import About from "../about/About";
import PopularQuestions from "../popularQuestions/PopularQuestions";
import Services from "../services/Services";
import GallerySlider from "../sliders/GallerySlider";
import ReviewsSlider from "../sliders/ReviewsSlider";
import {Helmet} from "react-helmet";
import NewsSlider from "../sliders/NewsSlider";

const HomePage = () => {
    return (
        <>

            <Helmet>
                <link rel="canonical" href="https://vipvozhdenie.by/"/>
                <title data-react-helmet="true">Автошкола Империя Вождения в Минске - обучение по лучшим ценам</title>
                <meta data-react-helmet="true" name="description" content="Автошкола Империя Вождения в Минске: ✅недорогие цены без переплат, ✅
                    опытные инструкторы, ✅ лучшие условия обучения. Более подробная информация по телефонам: ☎️ +375 29 509 0 508
                    +375 44 507 0 509 Звоните!"/>
                <meta data-react-helmet="true" name="keywords" content="автошкола, автошкола минск, автошкола в минске, автошкола
                    минск цены, мотошкола в минске, империя вождения, минск, вождение, обучение вождению, экзамен в ГАИ"/>
                <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/"/>
                <meta data-react-helmet="true" property="og:title"
                      content="Автошкола Империя Вождения в Минске - обучение по лучшим ценам"/>
                <meta data-react-helmet="true" property="og:description" content="Автошкола Империя Вождения в Минске:
                    ✅недорогие цены без переплат, ✅ опытные инструкторы, ✅ лучшие условия обучения. Более подробная информация по
                    телефонам: ☎️ +375(29)509-0-508 +375(44)507-0-509 Звоните!"/>
                <meta data-react-helmet="true" property="og:image"
                      content="https://vipvozhdenie.by/static/media/slide1.79d9a404dd68c5b73644.jpg"/>
            </Helmet>

            <Banner type="mainBanner"/>

            <Services>Мы предоставляем следующие услуги</Services>

            <Advantages/>

            <About/>

            <GallerySlider/>

            <PopularQuestions/>

            <NewsSlider/>

            <ReviewsSlider/>

            <Questions/>

        </>
    );
};

export default HomePage;
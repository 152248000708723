import React from 'react';
import styles from "./feedbackForm.module.scss";
import Button from "../button/Button";
import {useForm} from "react-hook-form";
import clsx from "clsx";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useHookFormMask } from 'use-mask-input';

const FeedbackForm = ({isModal}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const registerWithMask = useHookFormMask(register);

    async function onSubmit(data) {
        try {
            const formData = {
                name: data.username,
                phoneNumber: '+' + data.phoneNumber.replace(/\D/g, ""),
            }

            const formattedText =
                "*Новая заявка*\n" +
                "\n" +
                "*Имя:* " + formData.name + "\n" +
                "*Номер телефона:* " + formData.phoneNumber
            ;

            await axios.post(`https://api.telegram.org/bot${process.env.REACT_APP_API_TOKEN}/sendMessage`, {
                chat_id: process.env.REACT_APP_API_CHAT_ID,
                text: formattedText,
                parse_mode: 'Markdown',
            })
            .then(() => {
                reset();
                NotificationManager.success('Ваша заявка была успешно отправлена.', 'Спасибо за заявку!');
            });

        } catch (error) {
            NotificationManager.error('Что-то пошло не так.', 'Ошибка')
            console.error('Error:', error);
        }

    }

    return (
        <div className={styles.formWrapper}>
            <h3 className={styles.formTitle}>Заказать звонок</h3>
            <div className={styles.formText}>Перезвоним и подробно ответим на все Ваши вопросы</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={clsx(styles.formItemsWrapper, styles[isModal])}>
                    <div>
                        <input className={errors.username && styles.inputError} {...register("username", { required: true, pattern: /^[a-zA-Zа-яА-Я\s]+$/ })} placeholder="Введите имя" autoComplete="off" type="text"/>
                        <div className={clsx(styles.error, errors.username && styles.active)}>* Укажите имя</div>
                    </div>
                    <div>
                        <input className={errors.phoneNumber && styles.inputError} {...registerWithMask("phoneNumber", ['+375 (99) 999-99-99'], { required: true, pattern: /^\+375 \(\d{2}\) \d{3}-\d{2}-\d{2}$/ })} placeholder="Введите номер" autoComplete="off" type="text"/>
                        <div className={clsx(styles.error, errors.phoneNumber && styles.active)}>* Укажите номер телефона</div>
                    </div>
                </div>
                <Button type="submit" className={styles.formButton} buttonType="buttonGold">Записаться</Button>
            </form>
            <NotificationContainer/>
        </div>
    );
};

export default FeedbackForm;
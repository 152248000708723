import React from 'react';
import Contacts from "../contacts/Contacts";
import ContactPhones from "../contactPhones/ContactPhones";
import {Helmet} from "react-helmet";

const ContactsPage = () => {
    return (
        <>

            <Helmet>
                <link rel="canonical" href="https://vipvozhdenie.by/contacts/"/>
                <title data-react-helmet="true">Контакты автошколы "Империя Вождения" в Минске</title>
                <meta data-react-helmet="true" name="description"
                      content="Адрес, телефоны, время работы Автошколы Империя Вождения в Минске ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
                <meta data-react-helmet="true" property="og:type" content="website"/>
                <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/contacts/"/>
                <meta data-react-helmet="true" property="og:title"
                      content='Контакты автошколы "Империя Вождения" в Минске'/>
                <meta data-react-helmet="true" property="og:description"
                      content="Адрес, телефоны, время работы Автошколы Империя Вождения в Минске ☎️ +375(29)509-0-508 +375(44)507-0-509"/>
            </Helmet>

            <Contacts/>

            <ContactPhones/>

        </>
    );
};

export default ContactsPage;
import React from 'react';
import ErrorPage from "../errorPage/ErrorPage";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const NotFound = () => {
    const location = useLocation();

    if(location.pathname !== '/404') {
        window.location.replace("/404");
    }

    return (
        <>

            <Helmet>
                <link rel="canonical" href="https://vipvozhdenie.by/404"/>
                <title data-react-helmet="true">404</title>
                <meta data-react-helmet="true" name="description"
                      content=""/>
                <meta data-react-helmet="true" property="og:type" content="website"/>
                <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/404"/>
                <meta data-react-helmet="true" property="og:title"
                      content='404'/>
                <meta data-react-helmet="true" property="og:description"
                      content=""/>
                <meta data-react-helmet="true" property="og:image"
                      content=""/>
            </Helmet>

            <ErrorPage />
        </>
    );
};

export default NotFound;
import React from 'react';
import styles from './about.module.scss';
import clsx from "clsx";

const About = () => {
    return (
        <section id="about" className={styles.about}>
            <div className={clsx(styles.container, styles.aboutContainer)}>
                <h2 className={styles.aboutTitle}>О нас</h2>
                <div className={styles.aboutText}>Лучшая автошкола Минска "Империя Вождения" проводит профессиональную подготовку водителей категорий "A", "B", "C", "D", "CE".</div>
                <div className={styles.aboutText}>В современной автошколе Минска  Вы получите квалифицированное обучение по теоретическому курсу у преподавателей высшей категории, а также приобретете должные навыки безопасного вождения у самых высококлассных и опытных инструкторов - настоящих мастеров своего дела.</div>
                <div className={styles.aboutText}>Автошкола проводит курс практической и теоретической стажировки водителей транспортных стредств категории "CE" для их трудоустройства в ведущие отечественные и зарубежные транспортные компании.</div>
                <div className={styles.aboutText}>Автошкола "Империя Вождения" оказывает помощь в восстановлении навыков после длительных перерывов в вождении, а также после лишения водительского удостоверения.</div>
                <div className={styles.aboutText}>У нас Вы можете получить дополнительные занятия по вождению автомобиля, качественно подготовиться с экзамену в ГАИ.</div>
                <div className={styles.aboutText}>Уютный учебный класс нашей автошколы оборудован по самым современным стандартам и правилам, оснащен системой кондиционирования и расположен по адресу: <span className={styles.goldAddress}>г. Минск, проспект имени газеты "Звязда", д. 61, каб. 34.</span></div>
                <div className={styles.aboutText}>Большой автодром и разнообразные маршруты, в сочетании с современными учебными автомобилями и опытными инструкторами помогут Вам освоить курс вождения так, чтобы Вы без страха и волнения сели за руль любого транспортного средства.</div>
                <div className={styles.aboutText}>Мы готовим водителей не только для сдачи экзамена в ГАИ, но и, в первую очередь, для Вашей уверенности за рулем!</div>
            </div>
        </section>
    );
};

export default About;
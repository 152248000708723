import React from 'react';
import styles from './yandexMap.module.scss';

const YandexMap = () => {
    return (
        <iframe className={styles.yandexMap}
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A990a9ac5f4313571166d48311c10f7e3025890eab3fe744543524e8b765fd817&amp;source=constructor"
            frameBorder="0" title="Местоположение автошколы и автодрома">
        </iframe>
    );
};

export default YandexMap;
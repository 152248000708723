import React from 'react';
import News from "../news/News";
import {useParams} from "react-router-dom";
import {NewsList} from "../news/NewsList";
import {Helmet} from "react-helmet";

const NewsPage = () => {
    const { newsId } = useParams();
    const MainNews = [NewsList.find(item => item.id === +newsId)];
    const FilteredNews = NewsList.filter((e) => e.id !== +newsId).reverse();

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://vipvozhdenie.by/news/allnews/"/>
                <title data-react-helmet="true">Новости в автошколе "Империя Вождения" в Минске</title>
                <meta data-react-helmet="true" name="description"
                      content="Новости Автошколы Империя Вождения в Минске. Делится новостями и изменения в ПДД для автолюбителей ☎️ +375 29 509 0 508 +375 44 507 0 509"/>
                <meta data-react-helmet="true" property="og:type" content="website"/>
                <meta data-react-helmet="true" property="og:url" content="https://vipvozhdenie.by/news/allnews/"/>
                <meta data-react-helmet="true" property="og:title"
                      content='Новости в автошколе "Империя Вождения" в Минске'/>
                <meta data-react-helmet="true" property="og:description"
                      content="Новости Автошколы Империя Вождения в Минске. Делится новостями и изменения в ПДД для автолюбителей ☎️ +375(29)509-0-508 +375(44)507-0-509"/>
                <meta data-react-helmet="true" property="og:image"
                      content="https://vipvozhdenie.by/static/media/autopark.6a7fa22ea59d2d803430.jpg"/>
            </Helmet>

            {
                newsId !== 'allnews' && <News mainTitle="" item={MainNews}/>
            }

            {
                FilteredNews.length > 0 &&
                <News mainTitle={newsId === 'allnews' ? 'Наши новости' : 'Другие новости'} newsType="shortNews"
                      item={FilteredNews}/>
            }

        </>
    );
};

export default NewsPage;
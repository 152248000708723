import React from 'react';
import styles from "./news.module.scss";
import clsx from "clsx";
import {Link} from "react-router-dom";

const News = ({item, newsType, mainTitle}) => {
    const Title = mainTitle === 'Наши новости';

    return (
        <section className={styles.news}>
            {Title ? <h1 className={styles.newsTitle}>Новости автошколы</h1>
                :
                <h2 className={styles.newsTitle}>{mainTitle}</h2>
            }

            <div className={clsx(styles.container, styles.newsContainer)}>
                {item.map(e => {
                    return <Link to={'/news/' + e.id} className={styles.newsItem} key={e.id}>
                        <img src={e.image} alt={e.imageAlt}/>
                        <div className={styles.newsInfo}>
                            <div className={styles.infoTitle} dangerouslySetInnerHTML={{__html: e.title}}></div>
                            <div className={styles.infoDate}>{e.date}</div>
                            <div className={clsx(styles.infoText, styles[newsType])} dangerouslySetInnerHTML={{__html: e.text}}></div>

                            {newsType &&
                                <div className={styles.readMore}>Читать далее...</div>
                            }
                        </div>
                    </Link>
                })}

            </div>
        </section>
    );
};

export default News;
import React from 'react';
import styles from "./advantages.module.scss";
import {Gps, Wheel, Wallet, Bag} from './SvgIcons';

const ADVANTAGES = [
    {id: 1, icon: Gps, alt: 'Местонахождение', title: 'Удобное месторасположение автодрома', text: 'Район Озерцо, авторынок "Малиновка"'},
    {id: 2, icon: Wallet, alt: 'Кошелек', title: 'Удобная система оплаты', text: 'Низкие цены и рассрочка платежей'},
    {id: 3, icon: Wheel, alt: 'Колесо', title: 'Опытные инструкторы', text: 'Каждый из них подготовил более 1000 водителей'},
    {id: 4, icon: Bag, alt: 'Сумка', title: 'Помощь в трудоустройстве', text: 'Ряд известных транспортных компаний являются нашими партнерами'},
]

const Advantages = () => {
    return (
        <section className={styles.advantages}>
            <div className={styles.container}>
                <h2 className={styles.advantagesTitle}>Автошкола "Империя Вождения" работает с 2019 года и за это время подготовила более 5000 выпускников</h2>
                <div className={styles.advantagesWrapper}>
                    {ADVANTAGES.map(advantages =>
                        <div className={styles.advantagesItem} key={advantages.id}>
                            {advantages.icon}
                            <div className={styles.itemTitle}>{advantages.title}</div>
                            <div className={styles.itemText}>{advantages.text}</div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Advantages;
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styles from "./sliders.module.scss";
import clsx from "clsx";
import {REVIEWS_SETTINGS} from "./Settings";

const User = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7C16 8.06087 15.5786 9.07828 14.8284 9.82843C14.0783 10.5786 13.0609 11 12 11C10.9391 11 9.92172 10.5786 9.17157 9.82843C8.42143 9.07828 8 8.06087 8 7ZM8 13C6.67392 13 5.40215 13.5268 4.46447 14.4645C3.52678 15.4021 3 16.6739 3 18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H18C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 16.6739 20.4732 15.4021 19.5355 14.4645C18.5979 13.5268 17.3261 13 16 13H8Z" fill="#FBCD20"/>
    </svg>
)

const Reviews = [
    {id: 1, user: 'Юрий Кулеш', text: 'Прошел переподготовку на категорию СЕ, старший инструктор Владислав отличный ' +
            'мастер и прекрасный человек, спасибо большое вам за ваш труд'},
    {id: 2, user: 'Роман', text: 'Учился на категорию С, пойду ещё на СЕ. На категорию С, попал к ' +
            'инструктору Альберту , рассказал, показал, обучил. Сдал с первого раза. Цены адекватные, сроки ' +
            'обучения быстрые. Рекомендую.'},
    {id: 3, user: 'Сергей Тукаленко', text: 'Учился на "D" . Группа сдала с первого раза. Надумаю пойти на "СЕ", ' +
            'пойду только к ним) Мне понравилось👍'},
    {id: 4, user: 'Андрей', text: 'Проходил переподготовку на категорию Е. Прекрасная школа, отличный коллектив: ' +
            'девушка-преподаватель, инструктор Влад и директор с девизом "Своих не бросаем😊" всё сдадите обязательно, не бойтесь!!'},
    {id: 5, user: 'Елена', text: 'Получила категорию "С". Школу рекомендую! Инструктору отдельная благодарность!!!! ' +
            'Да и в целом, ребята молодцы!!!! Спасибо)'},
    {id: 6, user: 'Сергей', text: 'Супер автошкола для переподготовки,сдал все с первого раза.Даходчиво объясняют ' +
            'инструктора.Отдельное спасибо инструктору Альберту'},
    {id: 7, user: 'Дмитрий', text: 'Топовые инструкторы. Талант доходчиво объяснять. Мне кажется даже медведь ' +
            'после одного занятия поедет 😂'},
]

const ReviewsSlider = () => {
    return (
        <section className={clsx(styles.slider, styles.reviewsSlider)}>
            <h2 className={styles.sliderTitle}>Что о нас говорят наши выпускники</h2>
            <div className={clsx(styles.container, styles.sliderContainer)}>
                <Slider {...REVIEWS_SETTINGS}>
                    {Reviews.map(item =>
                        <div className={styles.reviewsItem} key={item.id}>
                            <div className={styles.reviewsUser}>{User}{item.user}</div>
                            <div className={styles.reviewsText}>{item.text}</div>
                        </div>
                    )}
                </Slider>

            </div>
        </section>
    );
};

export default ReviewsSlider;
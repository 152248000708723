import React from 'react';
import styles from './questions.module.scss';
import clsx from "clsx";
import YandexMap from "../yandexMap/YandexMap";
import FeedbackForm from "../feedbackForm/FeedbackForm";

const Questions = () => {
    return (
        <section id="contactUs" className={styles.questions}>
            <h2 className={styles.questionsTitle}>Остались вопросы?<br/>Свяжитесь с нами</h2>
            <div className={clsx(styles.container, styles.questionsContainer)}>
                <FeedbackForm />
                <YandexMap />
                <div className={styles.contactInformation}>
                    <div>
                        <div className={styles.contactInformationTitle}>Мы находимся</div>
                        <div className={styles.contactInformationText}>Пр-кт газеты "Звязда", д. 61, к. 34</div>
                        <div className={styles.contactInformationTitle}>Адрес автодрома</div>
                        <div className={styles.contactInformationText}>Авторынок "Малиновка"</div>
                    </div>
                    <div>
                        <div className={styles.contactInformationTitle}>Номера телефонов</div>
                        <div className={styles.contactInformationText}>
                            <span>A1: </span><a href="tel:+375 44 507 05 09">+375 44 507 05 09</a><br/>
                            <span>МТС: </span><a href="tel:+375 29 509 05 08">+375 29 509 05 08</a><br/>
                            <span>Городской: </span><a href="tel:+375 17 300 7 300">+375 17 300 7 300</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Questions;
import React from 'react';
import './sliderStyles.scss';
import styles from './sliders.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import slide1 from './img/homeSlider/slide1.jpg';
import slide2 from './img/homeSlider/slide2.jpg';
import slide3 from './img/homeSlider/slide3.jpg';
import clsx from "clsx";
import {GALLERY_SETTINGS} from "./Settings";

const GallerySlider = () => {
    return (
        <section className={clsx(styles.slider, styles.gallerySlider)}>
            <h2 className={styles.sliderTitle}>Галерея</h2>
            <div className={clsx(styles.container, styles.sliderContainer)}>
                <Slider {...GALLERY_SETTINGS}>
                    <img src={slide1} alt="Слайд 1"/>
                    <img src={slide2} alt="Слайд 2"/>
                    <img src={slide3} alt="Слайд 3"/>
                </Slider>
            </div>
        </section>
    );
};

export default GallerySlider;
import React, {useState} from 'react';
import styles from "./footer.module.scss";
import MainLogo from "../mainLogo/MainLogo";
import Navigation from "../navigation/Navigation";
import Button from "../button/Button";
import {Instagram, Telegram} from "./SvgIcons";
import clsx from "clsx";
import ModalForm from "../modalForm/ModalForm";

const Footer = () => {
    const [modalForm, setModalForm] = useState(false);

    const showForm = () => {
        document.body.style.overflow = "hidden";
        setModalForm(true)
    }

    return (
        <footer>
            <MainLogo classname={styles.footerLogo}/>
            <div className={clsx(styles.container, styles.footerContainer)}>
                <div className={styles.footerInfo}>
                    <div>
                        <div className={styles.infoTitle}>Мы находимся</div>
                        <div className={styles.infoText}>Пр-кт газеты "Звязда", д. 61, каб. 34</div>
                    </div>
                    <div>
                        <div className={styles.infoTitle}>Адрес автодрома</div>
                        <div className={styles.infoText}>Авторынок "Малиновка"</div>
                    </div>
                    <div>
                        <div className={styles.infoTitle}>Номера телефонов</div>
                        <div className={styles.infoText}>
                            <span>A1: </span><a href="tel:+375 44 507 05 09">+375 44 507 05 09</a><br/>
                            <span>МТС: </span><a href="tel:+375 29 509 05 08">+375 29 509 05 08</a><br/>
                            <span>Городской: </span><a href="tel:+375 17 300 7 300">+375 17 300 7 300</a>
                        </div>
                    </div>
                </div>
                <div className={styles.footerNavigation}>
                    <Navigation classname={styles.navigation}/>
                    <div>
                        <MainLogo />
                    </div>
                </div>
                <div className={styles.footerSocials}>
                    <div className={styles.socialsWrapper}>
                        <a aria-label="Телеграм" target="_blank" rel="noopener noreferrer" href="https://t.me/vipvozhdenie">{Telegram}</a>
                        <a aria-label="Инстаграм" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/vipvozhdenie/">{Instagram}</a>
                    </div>
                    <Button action={showForm} buttonType="buttonWhite">Заказать звонок</Button>
                    <ModalForm active={modalForm} setActive={setModalForm} />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
import React from 'react';
import styles from "./promotions.module.scss";
import clsx from "clsx";

const Promotions = () => {
    return (
        <section className={styles.promotions}>
            <h1 className={styles.promotionsTitle}>Акции в автошколе "Империя Вождения"</h1>
            <div className={clsx(styles.container, styles.promotionsContainer)}>
                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>10 % для студентов</h3>
                    <div className={styles.itemText}>Востребованная акция - скидка студентам, обучающимся в нашей автошколе</div>
                </div>
                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>10 % выпускникам</h3>
                    <div className={styles.itemText}>Каждый выпускник получает скидку на последующий курс обучения</div>
                </div>
                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>7 % для друзей</h3>
                    <div className={styles.itemText}>Акция - пригласи друга пройти обучение в нашей автошколе и получи персональную скидку</div>
                </div>
                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>7 % для многодетных</h3>
                    <div className={styles.itemText}>Скидка для родителей многодетных семей</div>
                </div>

                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>Подарочный сертификат</h3>
                    <div className={styles.itemText}>Лучший подарок для ваших близких - сертификат на обучение в лучшей автошколе города Минска</div>
                </div>
                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>Автомобиль для экзамена</h3>
                    <div className={styles.itemText}>Мы бесплатно предоставляем автомобиль для сдачи экзамена в ГАИ (в составе своей учебной группы)</div>
                </div>
                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>Скидка подписчикам</h3>
                    <div className={styles.itemText}>Для подписчиков <a aria-label="Инстаграм" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/vipvozhdenie/">нашего Instagram</a> - 20 BYN</div>
                </div>
                <div className={styles.promotionsItem}>
                    <h3 className={styles.itemTitle}>Оплата в рассрочку</h3>
                    <div className={styles.itemText}>Большое преимущество нашей автошколы - это рассрочка на весь период обучения</div>
                </div>
            </div>
        </section>
    );
};

export default Promotions;
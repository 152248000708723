import React from 'react';
import {NEWS_SETTINGS} from "./Settings";
import styles from "./sliders.module.scss";
import newsStyles from "./../news/news.module.scss"
import clsx from "clsx";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {NewsList} from "../news/NewsList";

const NewsItem = NewsList.slice(-3).reverse();

const NewsSlider = () => {
    return (
        <section className={clsx(styles.slider, styles.reviewsSlider)}>
            <h2 className={styles.sliderTitle}>Последние новости</h2>
            <div className={clsx(styles.container, styles.sliderContainer)}>
                <Slider {...NEWS_SETTINGS}>
                    {NewsItem.map(e => {
                        return <Link to={'/news/' + e.id} className={newsStyles.newsItem} key={e.id}>
                            <img src={e.image} alt={e.imageAlt}/>
                            <div className={newsStyles.newsInfo}>
                                <div className={newsStyles.infoTitle} dangerouslySetInnerHTML={{__html: e.title}}></div>
                                <div className={newsStyles.infoDate}>{e.date}</div>
                                <div className={clsx(newsStyles.infoText, newsStyles.shortNews)} dangerouslySetInnerHTML={{__html: e.text}}></div>

                                <div className={newsStyles.readMore}>Читать далее...</div>
                            </div>
                        </Link>
                    })}
                </Slider>
            </div>
        </section>
    );
};

export default NewsSlider;